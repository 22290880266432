import moment from "moment-timezone";

export default {
  formatDate(data, invalid = null) {
    if (!data) {
      return invalid;
    }

    return moment(data)
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("DD-MM-YYYY");
  },

  formatDateTime(data, invalid = null) {
    if (!data) {
      return invalid;
    }

    return moment(data)
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("YYYY-MM-DD HH:mm");
  },
  
  formatTime(data, invalid = null) {
    if (!data) {
      return invalid
    }

    const formatvalue = moment(data).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('HH:mm')
    if (formatvalue !== 'Invalid date') {
      return formatvalue
    }
    return data
  }
};
