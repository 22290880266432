<template>
  <div class="sidebar">
    <div class="logo">
      <span><img src="../../assets/Logo.svg" /></span>
    </div>

    <div class="menu">
      <router-link
        :to="{ name: 'MemberManagement' }"
        id="member"
        :class="
          $store.state.activeRoute === 'MemberManagement'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i
          :class="
            $store.state.activeRoute === 'MemberManagement'
              ? 'member-icon-selected selected'
              : 'member-icon'
          "
        ></i>
        <span class="menu-text">Member Management</span>
      </router-link>
      <router-link
        :to="{ name: 'LeasingItemManagement' }"
        id="member"
        :class="
          $store.state.activeRoute === 'LeasingItemManagement'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i
          :class="
            $store.state.activeRoute === 'LeasingItemManagement'
              ? 'leasingitem-icon-selected selected'
              : 'leasingitem-icon'
          "
        ></i>
        <span class="menu-text">Leasing Item Management</span>
      </router-link>
      <router-link
        :to="{ name: 'LeasingRecord' }"
        id="member"
        :class="
          $store.state.activeRoute === 'LeasingRecord'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i
          :class="
            $store.state.activeRoute === 'LeasingRecord'
              ? 'leasingrecord-icon-selected selected'
              : 'leasingrecord-icon'
          "
        ></i>
        <span class="menu-text">Leasing Record</span>
      </router-link>
      <router-link
        :to="{ name: 'CommentList' }"
        id="member"
        :class="
          $store.state.activeRoute === 'CommentList'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i
          :class="
            $store.state.activeRoute === 'CommentList'
              ? 'comment-icon-selected selected'
              : 'comment-icon'
          "
        ></i>
        <span class="menu-text">Comment Management</span>
      </router-link>
      <div
        class="menu-item cursor-pointer"
        @click="visibleDDMenu = !visibleDDMenu"
      >
        <i class="cms-icon"></i>
        <span class="menu-text">CMS Page Management</span>
        <i
          :class="{
            'chevron-up': !visibleDDMenu,
            'chevron-up': visibleDDMenu,
          }"
        ></i>
      </div>

      <router-link
        :to="{ name: 'AboutUs' }"
        id="about"
        v-if="visibleDDMenu"
        :class="
          $store.state.activeRoute === 'AboutUs'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <span class="menu-text ml-5">About Us</span>
      </router-link>

      <router-link
        :to="{ name: 'ContactUs' }"
        id="contact"
        v-if="visibleDDMenu"
        :class="
          $store.state.activeRoute === 'ContactUs'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <span class="menu-text ml-5">Contact Us</span>
      </router-link>
      <router-link
        :to="{ name: 'PrivacyPolicy' }"
        id="privacy"
        v-if="visibleDDMenu"
        :class="
          $store.state.activeRoute === 'PrivacyPolicy'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <span class="menu-text ml-5">Privacy Policy</span>
      </router-link>
      <router-link
        :to="{ name: 'TermsAndConditions' }"
        id="terms"
        v-if="visibleDDMenu"
        :class="
          $store.state.activeRoute === 'TermsAndConditions'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <span class="menu-text ml-5">Terms & Conditions</span>
      </router-link>
      <router-link
        :to="{ name: 'ProfileSetting' }"
        id="profile"
        :class="
          $store.state.activeRoute === 'ProfileSetting'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i
          :class="
            $store.state.activeRoute === 'ProfileSetting'
              ? 'profile-icon-selected selected'
              : 'profile-icon'
          "
        ></i>
        <span class="menu-text">Profile Setting</span>
      </router-link>
      <router-link
        :to="{ name: 'Login' }"
        id="logout"
        class="menu-item"
        @click="logout()"
      >
        <i class="logout-icon"></i>
        <span class="menu-text">Logout</span>
      </router-link>
    </div>
    <span class="footer">Top Choice Services Limited</span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visibleDDMenu: false,
      visibleDropdown: false,
    };
  },
  methods: {
    // hide() {
    //   this.visibleDDMenu = false;
    // },
    logout() {

      this.$store.dispatch("logout");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
<style scoped>
@import "@/assets/scss/sidebar.scss";
::-webkit-scrollbar {
  width: 5px;
}
.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  gap: 20px;
  width: 300px;
  order: 0;
  flex-grow: 0;
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  gap: 20px;
  width: 300px;
  order: 0;
  flex-grow: 0;
  color: #000;

  /* 16/EN-Regular */
  font-size: 16px;
  font-family: NunitoSans;
  font-weight: 400;
  line-height: 24px;
}
/* .dropdown-list-padding {
  padding-left: 57px;
}

.margin {
  margin-left: 17px !important;
  margin-top: 9px;
} */
</style>

