// services/validator.js
function isValidPassword(password) {
    if (password !== null) {
      // Check for at least one special character, one uppercase letter, one lowercase letter, and one numeric digit
      const specialCharRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/;
      const uppercaseRegex = /[A-Z]/;
      const lowercaseRegex = /[a-z]/;
      const numericRegex = /[0-9]/;
  
      return (
        specialCharRegex.test(password) &&
        uppercaseRegex.test(password) &&
        lowercaseRegex.test(password) &&
        numericRegex.test(password)
      );
    } else {
      return true;
    }
  }
  
  export { isValidPassword };
  