<template>
  <div>
    <InputNumber
      :id="id"
      :modelValue="modelValue"
      :disabled="disabled"
      :placeholder="placeholder"
      :min="min"
      :max="max"
      :useGrouping="useGrouping"
      :class="{
        'p-invalid': validation.$error,
      }"
      @keydown.space.prevent
      :aria-describedby="`${id}-help`"
      @input="$emit('update:modelValue', $event.value)"
    />
    <span
      v-if="
        validation?.$error &&
        validation.required &&
        validation.required.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.required.$message.replace("Value", this.label) }}
    </span>
    <span
      v-else-if="
        validation?.$error &&
        validation.minValue &&
        validation.minValue.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.minValue.$message }}
    </span>
    <span
      v-else-if="
        validation?.$error &&
        validation.maxValue &&
        validation.maxValue.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ message }}
    </span>
    <span
      v-else-if="
        validation?.$error && validation.min && validation.min.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.min.$message }}
    </span>
    <span
      v-else-if="
        validation?.$error && validation.max && validation.max.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.max.$message }}
    </span>
    <span
      v-else-if="
        validation?.$error && validation.numaric && validation.numaric.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.numaric.$message }}
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
    message: "The max length allowed is 8"
    }
  },
  props: {
    labelClass: {
      type: String,
      default: "form-label",
    },
    sectionClass: {
      type: String,
      default: "field",
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    label: {
      type: String,
      required: false,
    },
    validation: {
      type: Object,
      required: true,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    maxlength: {
      type: String,
      default: null,
    },
    minlength: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    labelshow: {
      type: Boolean,
      default: true,
    },
    timeonly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    useGrouping: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
