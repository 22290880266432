import DateFormat from "@/libs/DateFormat";

export default class Member {
  constructor(data = {}) {
    return {
      id: data.id || "",
      userid: data.userid || "",
      username: data.username || "",
      phone: data.phone || "",
      email: data.email || "",
      password: data.password || "",
      createddate: DateFormat.formatDate(data.createddate) || "",
    };
  }
}
