<template>
  <Dialog
    v-model:visible="display"
    modal
    header="Comment Details"
    @hide="closePopup"
  >
    <div>
      <table class="table-data">
        <tr>
          <th><span class="table-header">Username</span></th>
          <td>
            <span class="table-data-value">{{ Record.username }}</span>
          </td>
        </tr>
        <!-- <tr>
          <th><span class="table-header">Product Category</span></th>
          <td>
            <span class="table-data-value">{{ Record.category }}</span>
          </td>
        </tr> -->
        <tr>
          <th><span class="table-header">Product Name</span></th>
          <td>
            <span class="table-data-value">{{ Record.item }} </span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Comment</span></th>
          <td>
            <span class="table-data-value">{{ Record.comment }} </span>
          </td>
        </tr>
      </table>
      <div class="mt-3">
        <PrimeButton
          label="Delete"
          class="remove-btn"
          @click="deleteComment(id)"
        ></PrimeButton>
      </div>
    </div>
  </Dialog>
</template>

<script>
import APIService from "@/services/api-service.js";
import Dialog from "primevue/dialog";

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      display: false,
      IdForDelete: "",
      Record: {
        username: "",
        itemname: "",
        comment: "",
      },
    };
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
      required: true,
    },
    recordId: {
      type: String,
      required: true,
    },
  },
  watch: {
    showPopup: {
      handler(newValue) {
        if (newValue === true) {
          this.openPopup();
        }
      },
      deep: true,
      immediate: true,
    },
    recordId: {
      handler(newValue) {
        if (newValue !== "") {
          this.RecordView();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    RecordView() {
      APIService.get(`/comment/${this.recordId}`).then((response) => {
        if (response && response.data && response.data.success) {
          this.Record.username = response.data.data.username;
          this.Record.item = response.data.data.item;
          this.Record.comment = response.data.data.comment;
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.data.message,
            life: 3000,
          });
          this.$emit("displayNo");
          this.display = false;
        }
      });
    },
    deleteComment() {
      APIService.delete(`/comment/delete/${this.recordId}`).then((response) => {
        if (response && response.data && response.data.success) {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Comment deleted successfully",
            life: 3000,
          });
          this.deleteSuccess();
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.data.message,
            life: 3000,
          });
        }
      });
    },
    // async EditComment() {
    //   const isFormCorrect = await this.v$.$validate();
    //   if (!isFormCorrect) return;
    //   else {
    //     // EditComment() {
    //     let payload = {
    //       reply: this.Record.reply,
    //     };

    //     APIService.put("/comment", payload, this.recordId).then((response) => {
    //       if (response && response.data && response.data.success) {
    //         this.$toast.add({
    //           severity: "success",
    //           summary: "Success",
    //           detail: response.data.message,
    //           life: 3000,
    //         });
    //         this.deleteSuccess();
    //       } else {
    //         this.$toast.add({
    //           severity: "error",
    //           summary: "Error",
    //           detail: response.data.message,
    //           life: 3000,
    //         });
    //       }
    //     });
    //     // },
    //   }
    // },

    openPopup() {
      this.display = true;
    },
    closePopup() {
      this.display = false;
      this.$emit("displayNo");
    },
    deleteSuccess() {
      this.display = false;
      this.$emit("deleteSuccess");
    },
  },
};
</script>
<style lang="scss" scoped>
.reply-label {
  margin-top: 10px;
  font-family: NunitoSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4e5969;
}
::v-deep(.areaheight) {
  .p-inputtext {
  width: inherit !important;
  min-height: 140px !important;
  }
}
// .textarea-reply-style {
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   padding: 4px 12px;

//   width: 432px;
//   height: 140px;

//   background: #f2f3f5;
//   border-radius: 2px;

//   font-family: NunitoSans;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 22px;

//   display: flex;
//   align-items: center;

//   color: #1d2129;
//   // overflow-y: scroll;
//   box-shadow: none !important;
// }
</style>
