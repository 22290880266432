import LeasingRecord from "@/models/leasingrecord";

export default class LeasingRecordFactory {
  static createFromJson(json) {
    return new LeasingRecord(json);
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = [];
    jsonArray.forEach((item) => {
      jsonData.push(LeasingRecordFactory.createFromJson(item));
    });

    return jsonData;
  }
}
