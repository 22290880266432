import { createWebHistory, createRouter } from "vue-router";
import Store from "@/store/index";
import MainLayout from "@/views/main-layout.vue";
import Login from "@/views/login.vue";
import MemberManagement from "@/views/MemberManagement/member-list.vue";
// import MemberManagementCreate from "@/views/MemberManagement/member-form.vue";

import Add from "@/views/MemberManagement/add.vue";
import Edit from "@/views/MemberManagement/edit.vue";

import LeasingItemManagement from "@/views/LeasingItemManagement/leasingitem-list.vue";
import LeasingItemManagementCreate from "@/views/LeasingItemManagement/leasingitem-form.vue";
import LeasingRecord from "@/views/LeasingRecord/leasing-list.vue"
import CommentList from "@/views/CommentManagement/comment-list.vue"
import AboutUs from "@/views/CMS/aboutus.vue";
import ContactUs from "@/views/CMS/contactus.vue";
import PrivacyPolicy from "@/views/CMS/privacy-policy.vue";
import TermsAndConditions from "@/views/CMS/terms-condition.vue";
import ProfileSetting from "@/views/ProfileSettings/profile.vue";

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
          title: "Login",
        },
      },
    {
    component: MainLayout,
    children: [
        {
          path: "/member-management",
          name: "MemberManagement",
          component: MemberManagement,
          meta: {
            requiresAuth: true,
            module: "MemberManagement",
            title: "Member management",
          },
        },
        // {
        //   path: "/member-management/create",
        //   name: "MemberManagementCreate",
        //   component: MemberManagementCreate,
        //   meta: {
        //     requiresAuth: true,
        //     module: "MemberManagement",
        //     title: "Member Management > Add",
        //   },
        // },
        {
          path: "/member-management/add",
          name: "Add",
          component: Add,
          meta: {
            requiresAuth: true,
            module: "MemberManagement",
            title: "Member Management > Add",
          },
        },
        {
          path: "/member-management/edit/:id",
          name: "Edit",
          component: Edit,
          meta: {
            requiresAuth: true,
            module: "MemberManagement",
            title: "Member Management > Edit",
          },
        },
        // {
        //   path: "/member-management/edit/:id",
        //   name: "MemberManagementEdit",
        //   component: MemberManagementCreate,
        //   meta: {
        //     requiresAuth: true,
        //     module: "MemberManagement",
        //     title: "Member Management > Edit",
        //   },
        // },
        {
          path: "/leasingitem-management",
          name: "LeasingItemManagement",
          component: LeasingItemManagement,
          meta: {
            requiresAuth: true,
            module: "LeasingItemManagement",
            title: "Leasingitem Management",
          },
        },
        {
          path: "/leasingitem-management/create",
          name: "LeasingItemManagementCreate",
          component: LeasingItemManagementCreate,
          meta: {
            requiresAuth: true,
            module: "LeasingItemManagement",
            title: "Leasingitem Management > Add",
          },
        },
        {
          path: "/leasingitem-management/edit/:id",
          name: "LeasingItemManagementEdit",
          component: LeasingItemManagementCreate,
          meta: {
            requiresAuth: true,
            module: "LeasingItemManagement",
            title: "Leasingitem Management > Edit",
          },
        },
        {
          path: "/leasing",
          name: "LeasingRecord",
          component: LeasingRecord,
          meta: {
            requiresAuth: true,
            module: "LeasingRecord",
            title: "Leasing Record",
          },
        },
        {
            path: "/comment",
            name: "CommentList",
            component: CommentList,
            meta: {
              requiresAuth: true,
              module: "CommentList",
              title: "Comment List",
            },
          },
        {
          path: "/about-us",
          name: "AboutUs",
          component: AboutUs,
          meta: {
            requiresAuth: true,
            module: "AboutUs",
            title: "About Us",
          },
        },
        {
          path: "/contact-us",
          name: "ContactUs",
          component: ContactUs,
          meta: {
            requiresAuth: true,
            module: "ContactUs",
            title: "Contact Us",
          },
        },
        {
          path: "/privacy-policy",
          name: "PrivacyPolicy",
          component: PrivacyPolicy,
          meta: {
            requiresAuth: true,
            module: "PrivacyPolicy",
            title: "Privacy Policy",
          },
        },
        {
          path: "/terms-condition",
          name: "TermsAndConditions",
          component: TermsAndConditions,
          meta: {
            requiresAuth: true,
            module: "TermsAndConditions",
            title: "Terms And Conditions",
          },
        },
        {
          path: "/profile-setting",
          name: "ProfileSetting",
          component: ProfileSetting,
          meta: {
            requiresAuth: true,
            module: "ProfileSetting",
            title: "Profile Setting",
          },
        },
      ],
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});
// router.beforeEach((to) => {
//   Store.commit("setActiveRoute", to.meta.module);
// });
router.beforeEach((to, from, next) => {
  const title = "Top Choice Services Limited";
  if (
    Store.state.timeout == null ||
    (Store.state.timeout != null && Store.state.timeout > new Date())
  ) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (Store.state.hasLoggedIn) {
        Store.commit("setUserTimeout");
        Store.commit("setActiveRoute", to.meta.module);
        window.document.title =
          to.meta && to.meta.title ? title + " - " + to.meta.title : title;
        next();
        return;
      }
      Store.dispatch("logout");
      next("/login");
      return;
    } else if (to.name === undefined) {
      if (Store.state.hasLoggedIn) {
        Store.commit("setActiveRoute", to.meta.module);
        window.document.title =
          to.meta && to.meta.title ? title + " - " + to.meta.title : title;
        next();
        return;
      }
      Store.dispatch("logout");
      next("/login");
      return;
    } else {
      Store.commit("setUserTimeout");
      if (to.name == "Login" && Store.state.hasLoggedIn) {
        Store.commit("setActiveRoute", "MemberManagement");
        next("/member-management");
        return;
      } else {
        window.document.title =
          to.meta && to.meta.title ? title + " - " + to.meta.title : title;
        next();
        return;
      }
    }
  } else {
    Store.dispatch("logout");
    next("/login");
    return;
  }
});
export default router;
