export default class LeasingRecord {
    constructor(data = {}) {
        return {
            id: data.id || '',
            leasingNo: data.leasingNo || '',
            contactname: data.contactname || '',
            contactemail: data.contactemail || '',
            contactphone: data.contactphone || '',
            status:data.status || ''
        }
    }
}