<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="dashboard-margin">
    <!-- <span class="main-title mt-2 mb-2">User Management</span> -->
    <PrimeCard>
      <template #content>
        <DataTable
          :value="leasingrecord"
          :paginator="true"
          :lazy="true"
          class="p-datatable-lg p-datatable-customers"
          :rows="itemsPerPage"
          :totalRecords="totalRecords"
          dataKey="leasingno"
          :rowHover="true"
          :resizableColumns="true"
          @page="onPage($event)"
          @sort="onSort($event)"
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
        >
          <template #header>
            <div class="flex">
              <!-- <div class="col-6"></div> -->
              <!-- <div class="col-6"> -->
              <!-- <PrimeButton
                label="Add"
                class="add-button mr-4"
                @click="gotoCreateUser"
              ></PrimeButton> -->
              <!-- </div> -->
            </div>
          </template>
          <template #loading>Loading records, please wait...</template>
          <template #empty>No records found.</template>
          <Column field="leasingNo" header="Leasing No." sortable></Column>
          <Column field="contactname" header="Contact Name" sortable></Column>
          <Column field="contactemail" header="Contact Email" sortable></Column>
          <Column field="contactphone" header="Contact No." sortable></Column>
          <Column field="status" header="Status" sortable></Column>
          <Column field="action" header="Action">
            <template #body="data">
              <div class="flex">
                <PrimeButton
                  label="View"
                  class="edit-button"
                  @click="gotoRecordView(data.data.id)"
                />
                <!-- <PrimeButton
                  label="Delete"
                  class="delete-button ml-2"
                  @click="showDeletePopup(data.data.id)"
                /> -->
              </div>
            </template>
          </Column>
        </DataTable>
        <div>
          <LeasingPopup
            :show-popup="showPopup"
            :record-id="recordIdForView"
            @displayNo="closePopup()"
            @deleteSuccess="successDeletePopup()"
          ></LeasingPopup>
        </div>
      </template>
    </PrimeCard>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import LeasingRecordFactory from "@/factories/leasingrecord";
export default {
  data() {
    return {
      visibleDDMenu: false,
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      userIdForDelete: "",
      showPopup: false,

      recordIdForView: "",
      leasingrecord: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // gotoCreateUser() {
    //   this.$router.push({ name: "BookingTimeCreate" });
    // },
    // gotoUserEdit(userId) {
    //   this.$router.push({
    //     name: "ProductManagementEdit",
    //     params: { id: userId },
    //   });
    // },
    successDeletePopup() {
      this.showPopup = false;
      this.recordIdForView = "";
      this.getList();
    },
    closePopup() {
      this.showPopup = false;
      this.recordIdForView = "";
    },
    gotoRecordView(recordId) {
      this.showPopup = true;
      this.recordIdForView = recordId;
    },
    getList() {
      this.leasingrecord = [];
      // let encoded = encodeURIComponent(this.search);
      APIService.get(
        `/leasing/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}`
      ).then((response) => {
        // console.log(response, "res");
        if (response && response.data) {
          this.totalRecords = response.data.totalrecords;
          this.leasingrecord = LeasingRecordFactory.createFromJsonArray(
            response.data.data
          );
        }
      });
    },

    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getList();
    },
    // onSearch() {
    //   this.search = this.search.trimStart().trimEnd();
    //   this.currentPage = 0;
    //   this.getList();
    // },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
::v-deep(.p-card) {
  height: 100% !important;
  min-height: 93vh !important;
}
</style>
