export default class LeasingItem {
    constructor(data = {}) {
        return {
            id: data.id || '',
            itemid: data.itemid || '',
            name: data.name || '',
            price: data.price || '',
            description: data.description || '',
            image: data.image || '',
        }
    }
}