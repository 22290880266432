import Comment from "@/models/comment";

export default class CommentFactory {
  static createFromJson(json) {
    return new Comment(json);
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = [];
    jsonArray.forEach((item) => {
      jsonData.push(CommentFactory.createFromJson(item));
    });

    return jsonData;
  }
}
